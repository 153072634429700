.Button_xl {
    height: 100px;
    min-width: 240px;
    margin-bottom: 20px;
    font-size: 28px;
}

.Settings-Buttons {
    display: flex;
    flex-flow: column nowrap;
    padding: 10px 40px;
}

.DeliveryFormPage .ant-row {
    height: 40px;
}