.LeftMenu {
    display: grid;

    box-sizing: border-box;
    width: 188px;
    height: calc(100vh - 100px);
    padding-bottom: 20px;

    grid-template-columns: 188px;
    grid-template-rows: repeat(8, 1fr);
    row-gap: 16px;
}

.LeftMenu-Button {
    display: grid;
    overflow: hidden;
    align-items: stretch;
    justify-content: center;

    padding: 5px;

    cursor: pointer;

    color: #8092A0;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    font-size: 0.9rem;

    grid-template-rows: 1fr 20px;
}

.LeftMenu-Button:hover {
    transition: 1s background;

    background: #FFCAB1;
}

.LeftMenu-ButtonIcon {
    display: flex;
    overflow: hidden;
}

.LeftMenu-ButtonIconSvg {
    min-width: 100%;
    height: 100%;
}

.LeftMenu-ButtonText {
    text-align: center;
}