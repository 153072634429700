@import url(https://fonts.googleapis.com/css?family=Cairo&display=swap);



.Button {
    padding: 4px 20px;

    cursor: pointer;
    text-align: center;

    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    font-size: 1.35rem;
}

.Button:hover {
    -webkit-transition: 1s background;
    transition: 1s background;

    background: #FFCAB1;
}

.Button_type_red {
    color: rgba(241, 6, 6, 0.4);
}

.Button_type_green {
    color: #2A853D;
}
.DeliveryForm {
    display: grid;
    overflow: auto;

    padding: 20px 14px;

    background: #FFFFFF;

    grid-template-rows: auto 1fr auto;
    grid-row-gap: 1vh;
    row-gap: 1vh;
}

.DeliveryForm-Title {
    text-align: center;

    color: #141B25;

    font-size: 1.9rem;
}

.DeliveryForm-Fields {
    display: grid;
    overflow: auto;

    grid-row-gap: 10px;

    row-gap: 10px;
    grid-template-rows: repeat(9, 5vh);
}

@media(max-height: 600px) {
    .DeliveryForm-Fields {
        grid-template-rows: repeat(9, 20px);
    }
}

.DeliveryForm-Fields::-webkit-scrollbar {
    width: 0;
}

.Field {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
}

.Field-Label {
    text-align: right;

    color: #141B25;

    font-size: 1.35rem;
}

@media(max-height: 700px) {
    .Field-Label {
        font-size: 1rem;
    }
}

.Field-Value {
    box-sizing: border-box;
    padding: 0 10px;

    border: 2px solid rgba(128, 146, 160, 0.65);
    border-radius: 10px;
}

.Field-Value:focus {
    outline: none;
}

.DeliveryForm-Buttons {
    display: grid;

    text-transform: uppercase;

    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
       -moz-column-gap: 50px;
            column-gap: 50px;
    justify-self: flex-end;
}
.NavigationButtons {
    display: grid;
    align-content: space-between;

    padding: 40px 20px;

    background: #FFFFFF;

    grid-template-rows: repeat(4, 22%);
}

.NavigationButton {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    padding: 10px;

    cursor: pointer;
    text-align: center;

    color: #8092A0;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    font-size: 1.35rem;
}

.NavigationButton:hover {
    -webkit-transition: 1s background;
    transition: 1s background;

    background: #FFCAB1;
}
.DeliveryPage {
    display: grid;

    box-sizing: border-box;
    padding: 2vh calc(3vh + 1vw);

    grid-template-columns: 770fr 337fr;
    grid-template-rows: 1fr;
    grid-column-gap: calc(3vh + 1vw);
    -webkit-column-gap: calc(3vh + 1vw);
       -moz-column-gap: calc(3vh + 1vw);
            column-gap: calc(3vh + 1vw);
}
#components-form-demo-normal-login {
    display: flex;
}

#components-form-demo-normal-login .login-form {
    height: 10px;
    margin: calc((100vh - 400px) / 2) auto;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}

#components-form-demo-normal-login .login-form-button {
    width: 100%;
}
.ant-menu-inline {
    --left-menu-width: 200px;
}

.ant-menu-inline-collapsed {
    --left-menu-width: 80px;
}

.cart {
    width: calc(100vw - var(--left-menu-width));
    max-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 20px 10px;
}

.cart__tables {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
}

.cart__tables::-webkit-scrollbar {
    width: 0;
}

.cart__order-table {
    width: calc((100vw - var(--left-menu-width)) * 0.475);
    margin-right: 5%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    overflow: hidden;
}

.cart__order {
    height: calc(100vh - 160px - 20px);
    margin-bottom: 20px;
    overflow: hidden;
    margin-top: 10px;
}

.cart__table-header {
    border-right: 1px solid rgb(232, 232, 232);
    padding: 4px 8px;
    border-bottom: 1px solid rgb(232, 232, 232);
    display: block !important;
    text-align: center;
}

.sum-form {
    height: 160px;
}

.cart__buttons {
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-top: 20px;
}

.cart__products-table {
    width: calc((100vw - var(--left-menu-width)) * 0.475);
    overflow: hidden;
}

.cart__sum {
    display: flex;
    justify-content: space-between;
}

.sum__item {
    width: 45%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.sum__item_flex-start {
    justify-content: flex-start;
}

.cart__products-table_selected {
    background-color: #e6f7ff;
}

.addition {
    padding-left: 20px;
}

.warning {
    position: fixed;
    left: 30vw;
    top: 40vh;
    z-index: 2;
    width: 40vw;
    height: 20vh;
}

.addition__quantity {
    font-weight: bold;
}

.cart__order-table .ant-table-thead th,
.cart__products-table .ant-table-thead th {
    padding: 16px 4px;
    text-align: center;
}

.cart__order-table .ant-table-thead th:nth-child(1) div,
.cart__products-table .ant-table-thead th:nth-child(1) div {
    width: 40px;
    overflow: hidden;
}

.cart__order-table .ant-table-tbody td:nth-child(1),
.cart__products-table .ant-table-tbody td:nth-child(1) {
    width: 49px;
    text-align: center;
}

.cart__order-table .ant-table-thead th:nth-child(2) div {
    width: 45px;
    overflow: hidden;
}

.cart__order-table .ant-table-tbody td:nth-child(2) {
    width: 54px;
    text-align: center;
}

.cart__order-table .ant-table-thead th:nth-child(3),
.cart__order-table .ant-table-tbody td:nth-child(3) {
    flex-grow: 1;
    max-width: calc(100% - (34px * 3 + 49px + 2 * 54px));
}

.cart__products-table .ant-table-thead th:nth-child(2),
.cart__products-table .ant-table-tbody td:nth-child(2) {
    flex-grow: 1;
    max-width: calc(100% - (34px * 2 + 49px + 54px));
}

.cart__order-table .ant-table-thead th:nth-child(4) div,
.cart__products-table .ant-table-thead th:nth-child(3) div {
    width: 45px;
    overflow: hidden;
}

.cart__order-table .ant-table-tbody td:nth-child(4),
.cart__products-table .ant-table-tbody td:nth-child(3) {
    width: 54px;
    text-align: center;
}

.cart__order-table .ant-table-thead th:nth-child(5) div,
.cart__order-table .ant-table-thead th:nth-child(6) div,
.cart__order-table .ant-table-thead th:nth-child(7) div,
.cart__products-table .ant-table-thead th:nth-child(4) div,
.cart__products-table .ant-table-thead th:nth-child(5) div {
    width: 25px;
    overflow: hidden;
}

.cart__order-table .ant-table-tbody td:nth-child(5),
.cart__order-table .ant-table-tbody td:nth-child(6),
.cart__order-table .ant-table-tbody td:nth-child(7),
.cart__products-table .ant-table-tbody td:nth-child(4),
.cart__products-table .ant-table-tbody td:nth-child(5) {
    width: 34px;
    display: flex;
    place-content: center;
    align-items: center;
}

.cart__order-table .ant-table-tbody td,
.cart__products-table .ant-table-tbody td {
    padding: 8px 4px;
}

.cart__order-table .ant-table-thead tr,
.cart__products-table .ant-table-thead tr,
.cart__order-table .ant-table-tbody tr,
.cart__products-table .ant-table-tbody tr {
    display: flex;
    width: calc((100vw - var(--left-menu-width)) * 0.475 - 11px);
}

.ant-form-item[class] {
    margin-bottom: 0;
}

.cart__sum label[class] {
    font-size: 12px;
}

.cart__sum .ant-input[class] {
    height: 25px;
}

.cart__sum .ant-row {
    height: 30px;
}

.ChooseQuantity {
    height: 160px;
    width: 300px;
}

.BodyOfChooseQuantity {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100px;
}

.AdditionalRequest {
    height: 170px;
}

.AdditionalRequestBody {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    height: 110px;
}

.AdditionalRequestBody-Content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 100px;
}

.AdditionalRequestBody-ProductName,
.AdditionalRequestBody-Price,
.AdditionalRequestBody-Tax {
    display: flex;
    flex-flow: column nowrap;
}

.AdditionalRequestBody-ProductName {
    width: 40%;
}

.AdditionalRequestBody-Tax {
    width: 20%;
}

.AdditionalRequestBody-Price {
    width: 20%;
}

.AntButton_green {
    background-color: #009900;
    border-color: #009900;
    color: #fff;
}

.AntButton_green:hover {
    background-color: #00AA00;
    border-color: #00AA00;
    color: #fff;
}

.RedBorder {
    border-color: red !important;
}

.ChooseTypeOfPayment .ant-modal-body {
    display: flex;
    flex-flow: column nowrap;
    padding: 40px 24px 24px 24px;
}

.ChooseTypeOfPayment-Button {
    margin-bottom: 8px;
}

.ChooseTypeOfPayment-Button_selected {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
}

.ChooseTypeOfPayment-Button_selected.ant-btn-danger {
    color: #fff;
    background-color: #ff7875;
    border-color: #ff7875;
}

.cart__products-table .ant-input[class] {
    min-height: unset;
}
.ArticleSettings {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.ArticleSettings-LeftPart {
    width: 48%;
}

.ArticleSettings-RightPart {
    width: 48%;
}

.ArticlePrices {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.PriceName {
    margin-right: 10px;
}

.PricesNames {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
}

.PricesTable {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
}

.Checkboxes {
    display: grid;
    grid-template-columns: repeat(2, auto);
}

.Checkboxes .ant-checkbox-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.Buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 30px;
    margin-right: 30px;
}
.LeftMenu {
    display: grid;

    box-sizing: border-box;
    width: 188px;
    height: calc(100vh - 100px);
    padding-bottom: 20px;

    grid-template-columns: 188px;
    grid-template-rows: repeat(8, 1fr);
    grid-row-gap: 16px;
    row-gap: 16px;
}

.LeftMenu-Button {
    display: grid;
    overflow: hidden;
    align-items: stretch;
    justify-content: center;

    padding: 5px;

    cursor: pointer;

    color: #8092A0;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    font-size: 0.9rem;

    grid-template-rows: 1fr 20px;
}

.LeftMenu-Button:hover {
    -webkit-transition: 1s background;
    transition: 1s background;

    background: #FFCAB1;
}

.LeftMenu-ButtonIcon {
    display: flex;
    overflow: hidden;
}

.LeftMenu-ButtonIconSvg {
    min-width: 100%;
    height: 100%;
}

.LeftMenu-ButtonText {
    text-align: center;
}
.menu {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
.ant-layout {
    background-color: #fff;
}

.ant-form-item {
    margin-bottom: 8px;
}

.ant-form .label {
    margin-bottom: 8px;
}

.ant-form label {
    font-size: 17px;
}

.ant-card-body {
    padding: 18px 24px 0px 24px;
}

.ant-layout-sider-trigger {
    border-right: 1px solid rgb(232, 232, 232);
}

.Header {
    display: grid;

    color: #F7F8F9;
    border-bottom: 4px solid rgba(255, 202, 177, 0.77);
    background: #141B25;

    font-size: 1.35rem;

    grid-template-columns: 188px calc(100vw - 188px);
}

.Header-VoipStatus {
    display: flex;
    align-items: center;
    justify-content: center;

    border-right: 1px solid #384955;
}

.Header-Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 52px 0 29px;
}

.Header-ActionsButtons {
    display: grid;

    grid-template-columns: repeat(3, auto);
    grid-column-gap: 53px;
    -webkit-column-gap: 53px;
       -moz-column-gap: 53px;
            column-gap: 53px;
}

.Header-ActionButton {
    cursor: pointer;
}

.Header-ActionButton:hover {
    opacity: 0.7;
}
.Button_xl {
    height: 100px;
    min-width: 240px;
    margin-bottom: 20px;
    font-size: 28px;
}

.Settings-Buttons {
    display: flex;
    flex-flow: column nowrap;
    padding: 10px 40px;
}

.DeliveryFormPage .ant-row {
    height: 40px;
}
.CategorySettingsPage {
    padding: 20px;
}

.CategorySettings {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.CategorySettings-LeftForm {
    width: 45%;
}

.CategorySettings-RightForm {
    width: 45%;
}

.CategorySettings .ant-form-item-label {
    text-align: left;
}

.CategorySettingsPage-Buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
}

.Modal-Buttons {
    display: flex;
    justify-content: center;
}

.Modal-Buttons>button:first-child {
    margin-right: 20px;
}

.CategorySettingsPage-Confirm {
    max-width: 300px;
}

.CategorySettings-ActionsIcons {
    display: flex;
    justify-content: space-around;
}

.CategorySettings-ActionsIcons svg {
    width: 20px;
    height: 20px;
}

.ImagePreview {
    max-width: 40vw;
    padding: 20px 0px;
    max-height: 200px;
}

.CategorySettings-RightForm .ant-col,
.CategorySettings-LeftForm .ant-col {
    overflow: hidden;
    text-overflow: ellipsis;
}
.VoipForm {
    padding: 40px;
}
html {
    font-size: 20px;
}

body {
    margin: 0;
}

@media (max-height: 1000px) {
    html {
        font-size: 15px;
    }
}

.App {
    display: grid;

    font-family: Cairo;

    grid-template-rows: 10vh 90vh;
}

.App-Main {
    display: grid;

    background-color: rgba(234, 236, 237, 0.36);

    grid-template-columns: 188px 1fr;
}
