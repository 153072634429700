.NavigationButtons {
    display: grid;
    align-content: space-between;

    padding: 40px 20px;

    background: #FFFFFF;

    grid-template-rows: repeat(4, 22%);
}

.NavigationButton {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    padding: 10px;

    cursor: pointer;
    text-align: center;

    color: #8092A0;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    font-size: 1.35rem;
}

.NavigationButton:hover {
    transition: 1s background;

    background: #FFCAB1;
}