#components-form-demo-normal-login {
    display: flex;
}

#components-form-demo-normal-login .login-form {
    height: 10px;
    margin: calc((100vh - 400px) / 2) auto;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}

#components-form-demo-normal-login .login-form-button {
    width: 100%;
}