.CategorySettingsPage {
    padding: 20px;
}

.CategorySettings {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.CategorySettings-LeftForm {
    width: 45%;
}

.CategorySettings-RightForm {
    width: 45%;
}

.CategorySettings .ant-form-item-label {
    text-align: left;
}

.CategorySettingsPage-Buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
}

.Modal-Buttons {
    display: flex;
    justify-content: center;
}

.Modal-Buttons>button:first-child {
    margin-right: 20px;
}

.CategorySettingsPage-Confirm {
    max-width: 300px;
}

.CategorySettings-ActionsIcons {
    display: flex;
    justify-content: space-around;
}

.CategorySettings-ActionsIcons svg {
    width: 20px;
    height: 20px;
}

.ImagePreview {
    max-width: 40vw;
    padding: 20px 0px;
    max-height: 200px;
}

.CategorySettings-RightForm .ant-col,
.CategorySettings-LeftForm .ant-col {
    overflow: hidden;
    text-overflow: ellipsis;
}