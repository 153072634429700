.menu {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
.ant-layout {
    background-color: #fff;
}

.ant-form-item {
    margin-bottom: 8px;
}

.ant-form .label {
    margin-bottom: 8px;
}

.ant-form label {
    font-size: 17px;
}

.ant-card-body {
    padding: 18px 24px 0px 24px;
}

.ant-layout-sider-trigger {
    border-right: 1px solid rgb(232, 232, 232);
}
