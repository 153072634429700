.ArticleSettings {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.ArticleSettings-LeftPart {
    width: 48%;
}

.ArticleSettings-RightPart {
    width: 48%;
}

.ArticlePrices {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.PriceName {
    margin-right: 10px;
}

.PricesNames {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
}

.PricesTable {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
}

.Checkboxes {
    display: grid;
    grid-template-columns: repeat(2, auto);
}

.Checkboxes .ant-checkbox-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.Buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 30px;
    margin-right: 30px;
}